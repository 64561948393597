<template>
    <div class="max">
        <div class="spinner over" v-show="formLoading">
            <div class="d-flex justify-content-center">
                <b-spinner label="Spinning" class="text-primary"></b-spinner>
            </div>
        </div>
        <b-row v-show="!formLoading">
            <b-col cols="12" md="6" lg="5">
                <label>{{ $t('add') }}</label>
                <div class="border p-4 rounded-sm">
                    <ValidationObserver ref="formModalValidate">
                        <b-row>
                            <b-col cols="12">
                                <ValidationProvider name="type" rules="required" v-slot="{ errors }">
                                    <b-form-group :label="$t('type')">
                                        <parameter-selectbox v-model="formData.type" code="section_detail_type"
                                            :validate-error="errors[0]" />
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="12"
                                v-if="['faculty', 'irregular_faculty', 'irregular', 'program', 'program_quota', 'class', 'suspension', 'repeat', 'surname', 'nationality_code', 'ects_credit', 'department', 'registration_type'].includes(formData.type)">
                                <ValidationProvider name="faculty_code"
                                    :rules="['faculty', 'irregular', 'program', 'program_quota', 'suspension', 'repeat', 'surname', 'nationality_code', 'ects_credit', 'department', 'registration_type'].includes(formData.type) ? 'required' : ''"
                                    v-slot="{ errors }">
                                    <b-form-group :label="$t('faculty')">
                                        <faculty-selectbox v-model="formData.faculty_code" :validateError="errors[0]"
                                            :multiple="formData.type == 'faculty' ? true : false"
                                            :selectAllBtn="formData.type == 'faculty' ? true : false" />
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="12"
                                v-if="['irregular', 'program', 'program_quota', 'class', 'suspension', 'repeat', 'surname', 'nationality_code', 'ects_credit', 'department'].includes(formData.type)">
                                <ValidationProvider name="department_code"
                                    :rules="['irregular', 'program', 'program_quota', 'suspension', 'repeat', 'surname', 'nationality_code', 'ects_credit', 'department'].includes(formData.type) ? 'required' : ''"
                                    v-slot="{ errors }">
                                    <b-form-group :label="$t('department')">
                                        <department-selectbox v-model="formData.department_code"
                                            :multiple="formData.type == 'department'" :faculty_code="formData.faculty_code"
                                            :validateError="errors[0]" />
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="12"
                                v-if="['irregular', 'program', 'program_quota', 'class', 'suspension', 'repeat', 'surname', 'nationality_code', 'ects_credit'].includes(formData.type)">
                                <ValidationProvider name="program_code" rules="required" v-slot="{ errors }">
                                    <b-form-group :label="$t('program')">
                                        <program-selectbox-multiple @input="programSelect" v-model="formData.program_code"
                                            :faculty_code="formData.faculty_code"
                                            :department_code="formData.department_code" :validateError="errors[0]" />
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="12"
                                v-if="formData.type && !['class', 'irregular', 'irregular_faculty', 'surname', 'ects_credit', 'foreign_student', 'registration_type', 'department', 'faculty', 'program', 'suspension', 'repeat'].includes(formData.type)">
                                <ValidationProvider name="detail"
                                    :rules="formData.type && !['class', 'irregular', 'surname', 'ects_credit', 'foreign_student', 'registration_type', 'department', 'faculty', 'program', 'suspension', 'repeat'].includes(formData.type) ? 'required' : ''"
                                    v-slot="{ errors }">
                                    <b-form-group
                                        :label="['non_dep', 'program_quota'].includes(formData.type) ? $t('quota') : $t('detail')">
                                        <b-form-textarea :placeholder="formData.type == 'non_dep' ? $t('quota') : null"
                                            v-model="formData.detail"></b-form-textarea>
                                        <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="12" v-if="formData.type == 'registration_type'">
                                <ValidationProvider name="type"
                                    :rules="formData.type == 'registration_type' ? 'required' : ''" v-slot="{ errors }">
                                    <b-form-group :label="$t('registration_type')">
                                        <parameter-selectbox valueType="code" code="registration_types"
                                            :validate-error="errors[0]" v-model="formData.registration_type">
                                        </parameter-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="12" v-if="formData.type && formData.type == 'class'">
                                <ValidationProvider name="classes" :rules="formData.type == 'class' ? 'required' : ''"
                                    v-slot="{ errors }">
                                    <b-form-group :label="$t('class')">
                                        <classes-selectbox v-model="formData.class" :validate-error="errors[0]"
                                            :multiple="true"></classes-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="12" v-if="['class'].includes(formData.type)">
                                <ValidationProvider name="quota" rules="" v-slot="{ errors }">
                                    <b-form-group :label="$t('quota')">
                                        <b-form-input type="number" min="0" max="100" v-check-min-max-value
                                            v-model="formData.quota" :state="errors[0] ? false : null" />
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="12" v-if="formData.type && ['surname'].includes(formData.type)">
                                <div class="d-flex align-items-center">
                                    <div class="flex-grow-1">
                                        <ValidationProvider name="surnameFiled1" v-slot="{ errors }">
                                            <b-form-group>
                                                <b-form-input v-mask="upperAlphaMask"
                                                    v-model="formData.surnameFiled1"></b-form-input>
                                                <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]">
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="px-3">
                                        <label>-</label>
                                    </div>
                                    <div class="flex-grow-1">
                                        <ValidationProvider name="surnameFiled2" v-slot="{ errors }">
                                            <b-form-group>
                                                <b-form-input v-mask="upperAlphaMask"
                                                    v-model="formData.surnameFiled2"></b-form-input>
                                                <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]">
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </b-col>
                            <b-col cols="12" v-if="formData.type && ['ects_credit'].includes(formData.type)">
                                <div class="d-flex align-items-center">
                                    <div class="flex-grow-1">
                                        <ValidationProvider name="numberFiled1"
                                            :rules="formData.type && ['ects_credit'].includes(formData.type) ? 'required' : ''"
                                            v-slot="{ errors }">
                                            <b-form-group>
                                                <b-form-input v-mask="'###'" v-model="formData.numberFiled1"></b-form-input>
                                                <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]">
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="px-3">
                                        <label>-</label>
                                    </div>
                                    <div class="flex-grow-1">
                                        <ValidationProvider name="numberFiled2"
                                            :rules="formData.type && ['ects_credit'].includes(formData.type) ? 'required' : ''"
                                            v-slot="{ errors }">
                                            <b-form-group>
                                                <b-form-input v-mask="'###'" v-model="formData.numberFiled2"></b-form-input>
                                                <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]">
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </b-col>
                            <b-col cols="12" v-if="formData.type">
                                <ValidationProvider name="invert" v-slot="{ errors }">
                                    <b-form-group :label="$t('invert')" v-slot="{ ariaDescribedby }">
                                        <b-form-radio-group v-model="formData.invert"
                                            :options="[{ 'value': 0, 'text': $t('no') }, { 'value': 1, 'text': $t('yes') }]"
                                            :aria-describedby="ariaDescribedby" name="radio-inline" />
                                        <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                    </ValidationObserver>
                </div>
                <div class="d-flex justify-content-center mt-3 mb-3">
                    <b-button variant="primary" @click="createForm" :disabled="formProcess">
                        {{ $t('add') }}
                    </b-button>
                </div>
            </b-col>
            <b-col cols="12" md="6" lg="7">
                <div v-if="details && details.length > 0">
                    <label>{{ $t('details') }}</label>
                    <div class="border p-4 rounded-sm">
                        <b-table bordered striped hover :fields="detailsFields" :items="details">
                            <template #cell(type)="data">
                                {{ getLocaleText(data.item, 'type_name') }}
                            </template>
                            <template #cell(detail)="data">
                                {{ data.item.detail_code_name ? getLocaleText(data.item, 'detail_code_name') : '' }}
                                <span v-if="data.item.detail_explanation"><br />{{
                                    getLocaleText(data.item, 'detail_explanation') }}
                                </span>
                                {{ data.item.quota > 0 ? `${$t('quota')}: ${data.item.quota}` : '' }}
                            </template>
                            <template #cell(invert)="data">
                                {{ data.item.invert ? $t('yes') : '-' }}
                            </template>
                            <template #cell(delete)="data">
                                <b-button variant="danger" size="sm" @click="deleteForm(data.item.id)">
                                    {{ $t('delete').toUpper() }}
                                </b-button>
                            </template>
                        </b-table>
                    </div>
                </div>
            </b-col>
        </b-row>

    </div>
</template>

<script>
// Components
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
import DepartmentSelectbox from "@/components/interactive-fields/DepartmentSelectbox";
import ProgramSelectboxMultiple from "@/components/interactive-fields/ProgramSelectboxMultiple";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import ClassesSelectbox from "@/components/interactive-fields/ClassesSelectbox";

// Other
import { ValidationProvider, ValidationObserver } from "vee-validate"

// Services
import SectionService from "@/services/SectionService"
import SectionDetailService from "@/services/SectionDetailService";
import qs from "qs";

export default {
    components: {
        FacultySelectbox,
        DepartmentSelectbox,
        ProgramSelectboxMultiple,
        ParameterSelectbox,
        ClassesSelectbox,

        ValidationProvider,
        ValidationObserver,
    },
    props: {
        formId: {
            type: Number
        }
    },
    data() {
        return {
            section: {},
            upperAlphaMask: {
                mask: 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA',
                tokens: {
                    'A': {
                        pattern: /[A-Za-z ÇĞİÖŞÜçğıöşü]/,
                        transform: function (v) {
                            v = v.replaceAll('i', 'İ');
                            return v.toUpper();
                        }
                    }
                }
            },


            details: {},
            detailsFields: [
                {
                    key: 'type',
                    label: this.$t('type').toUpper(),
                    sortable: true,
                    tdClass: 'align-middle'
                },
                {
                    key: 'detail',
                    label: this.$t('detail').toUpper(),
                    sortable: true,
                    tdClass: 'align-middle'
                },
                {
                    key: 'invert',
                    label: this.$t('invert').toUpper(),
                    sortable: true,
                    tdClass: 'align-middle'
                },
                {
                    key: 'delete',
                    label: '',
                    tdClass: 'text-center width-50',
                    sortable: true
                }
            ],
            formData: {
                type: null,
                registration_type: null,
                faculty_code: null,
                department_code: null,
                program_code: [],
                detail: null,
                invert: 0,
                surnameFiled1: null,
                surnameFiled2: null,
                numberField1: null,
                numberField2: null,
                class: null
            },
            formLoading: false,
            formProcess: false
        }
    },
    created() {
        if (this.formId) {
            this.getSection(this.formId);
        }
    },

    watch: {
        'formData.type': {
            handler(value){
                this.formData.detail = null
            }
        }
    },

    methods: {
        programSelect() { },
        formDataClear() {
            this.formData = {
                type: null,
                faculty_code: null,
                department_code: null,
                program_code: [],
                detail: null,
                invert: 0,
                surnameFiled1: null,
                surnameFiled2: null
            }
        },

        getSection(id) {
            this.formLoading = true
            SectionService.get(id)
                .then(response => {
                    this.section = response.data.data;
                })
                .then(() => {
                    this.getDetails(this.section.id);
                })
                .catch(() => {
                    this.$router.push('/404')
                })
                .finally(() => {
                    this.formLoading = false;
                })
        },

        getDetails(id) {
            const config = {
                params: {
                    filter: {
                        section_id: id
                    },
                    limit: -1,
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            };
            SectionDetailService.getAll(config)
                .then(response => {
                    this.details = response.data.data;
                })
        },

        // Create
        async createForm() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid) {
                this.formProcess = true;

                let formData = {};
                formData.section_id = this.section.id;
                formData.type = this.formData.type;
                formData.detail = this.formData.detail;
                formData.invert = this.formData.invert;
                if (formData.type == 'faculty') {
                    if (this.formData.faculty_code != '') {
                        let codes = this.formData.faculty_code
                        if (codes && codes.length > 0) {
                            codes.forEach((code) => {
                                formData.detail_code = code;
                                this.createFormAction(formData);
                            })
                        }
                    }
                }

                else if (formData.type == 'department') {
                    let codes = this.formData.department_code
                    if (codes && codes.length > 0) {
                        codes.forEach((code) => {
                            formData.detail_code = code;
                            this.createFormAction(formData);
                        })
                    }
                }
                else if (formData.type == 'registration_type') {
                    formData.detail = this.formData.registration_type;
                    formData.detail_code = this.formData.faculty_code;
                    this.createFormAction(formData);
                }
                else if (['surname'].includes(formData.type)) {
                    formData.detail = this.formData.surnameFiled1 != null && this.formData.surnameFiled1 != null ? this.formData.surnameFiled1 + '-' + this.formData.surnameFiled2 : null
                    if (this.formData.program_code != '') {
                        let codes = this.formData.program_code
                        if (codes && codes.length > 0) {
                            codes.forEach((code) => {
                                formData.detail_code = code;
                                this.createFormAction(formData);
                            })
                        }
                    }
                }
                else if (['ects_credit'].includes(formData.type)) {

                    if (this.formData.numberFiled1 > this.formData.numberFiled2) {
                        this.$refs.formModalValidate.errors.numberFiled1.push(this.$t('first_value_big_from_second'))
                        this.formProcess = false
                        return false
                    }
                    formData.detail = this.formData.numberFiled1 != null && this.formData.numberFiled1 != null ? this.formData.numberFiled1 + '-' + this.formData.numberFiled2 : null
                    if (this.formData.program_code != '') {
                        let codes = this.formData.program_code
                        if (codes && codes.length > 0) {
                            codes.forEach((code) => {
                                formData.detail_code = code;
                                this.createFormAction(formData);
                            })
                        }
                    }

                }
                else if (formData.type == 'class') {
                    formData.detail = this.formData.class.toString()
                    if (Number(this.formData.quota) > 0) {
                        formData.quota = Number(this.formData.quota)
                    } else {
                        formData.quota = null
                    }

                    if (this.formData.program_code != '' && this.formData.program_code != null) {
                        let codes = this.formData.program_code
                        if (codes && codes.length > 0) {
                            codes.forEach((code) => {
                                formData.detail_code = code;
                                this.createFormAction(formData);
                            })
                        }
                    } else {
                        this.createFormAction(formData);
                    }
                }
                else if (['irregular', 'program', 'program_quota', 'suspension', 'repeat', 'nationality_code'].includes(formData.type)) {
                    if (Number(this.formData.detail) > 0) {
                        formData.quota = Number(this.formData.detail)
                    } else {
                        formData.quota = null
                    }
                    delete formData.detail
                    if (this.formData.program_code != '') {
                        let codes = this.formData.program_code
                        if (codes && codes.length > 0) {
                            codes.forEach((code) => {
                                formData.detail_code = code;
                                this.createFormAction(formData);
                            })
                        }
                    } else {
                        formData.detail_code = '';
                        this.createFormAction(formData);
                    }
                } else if (["irregular_faculty"].includes(formData.type)) {
                    formData.detail_code = this.formData.faculty_code;
                    this.createFormAction(formData);
                }
                else {
                    formData.detail_code = '';
                    this.createFormAction(formData);
                }
                this.formProcess = false;
            }
        },
        async createFormAction(formData) {
            if (this.checkPermission('sectiondetail_store')) {
                await SectionDetailService.store(formData)
                    .then(response => {
                        this.formDataClear();
                        this.$toast.success(this.$t('api.' + response.data.message));
                        this.$refs.formModalValidate.reset();

                        this.getDetails(this.section.id);
                    })
                    .catch(e => {
                        if (e.status == 422) {
                            if (e.data.errors.type) this.$refs.formModalValidate.errors.type.push(e.data.errors.type[0]);
                            if (formData.type) {
                                if (formData.type == 'faculty') {
                                    if (e.data.errors.detail_code) this.$refs.formModalValidate.errors.faculty_code.push(e.data.errors.detail_code[0]);
                                }
                                else if (['irregular', 'program', 'program_quota', 'class', 'suspension', 'repeat', 'surname'].includes(formData.type)) {
                                    if (e.data.errors.detail_code) this.$refs.formModalValidate.errors.program_code.push(e.data.errors.detail_code[0]);
                                }
                            }
                            if (e.data.errors.detail) this.$refs.formModalValidate.errors.detail.push(e.data.errors.detail[0]);
                            if (e.data.errors.invert) this.$refs.formModalValidate.errors.invert.push(e.data.errors.invert[0]);
                            if (e.data.errors.detail) this.$refs.formModalValidate.errors.surnameFiled1.push(e.data.errors.detail[0]);
                        }
                        else if (e.status == 406) {
                            this.$toast.error(this.$t('api.' + e.data.message));
                        }
                    })
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }

        },

        // Delete
        deleteForm(id) {
            if (this.checkPermission('sectiondetail_delete')) {
                this.$swal.fire({
                    text: this.$t('are_you_sure_to_delete'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no'),
                })
                    .then((result) => {
                        if (result.isConfirmed) {
                            SectionDetailService.del(id)
                                .then(response => {
                                    this.$toast.success(this.$t('api.' + response.data.message));
                                    this.getDetails(this.section.id);
                                })
                                .catch(error => {
                                    this.$toast.error(this.$t('api.' + error.data.message));
                                });
                        }
                    })
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }

        },
    }
}
</script>

