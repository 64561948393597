<template>
	<div>
		<multiselect
			:placeholder="$t('select')"
			v-model="selected"
			:options="options"
			:class="validateError != '' ? 'box-border-color' : ''"
			@input="handleInput($event, multiple)"
			label="text"
			track-by="value"
			:multiple="multiple"
			:close-on-select="!multiple"
			:clear-on-select="!multiple"
			:select-label="''"
			:selected-label="''"
			:deselect-label="''"
		>
			<template slot="selection" slot-scope="{ values, search, isOpen }" v-if="multiple">
		        <span class="multiselect__single" v-if="values.length && !isOpen">
			        {{ translateNSelected(values) }}
		        </span>
			</template>
			<span slot="noOptions">{{ $t("no_options") }}</span>
			<span slot="noResult">{{ $t("no_result") }}</span>
		</multiselect>
		<span class="invalid-feedback-custom" v-show="validateError" v-html="validateError"/>
	</div>
</template>

<script>
	// Helpers
	import translateNSelected from "@/helpers/translateNSelected";
	import handleInput from "@/helpers/handleInput";
	import setSelected from "@/helpers/setSelected";
	
	// Services
	import ProgramServices from "@/services/ProgramService";
	
	// Other
	import qs from "qs";
	
	export default {
		props: {
			faculty_code: {
				default: null,
			},
			department_code: {
				default: null,
			},
			major_type: {
				type: String,
				default: null,
			},
			value: {
				default: null,
			},
			multiple: {
				type: Boolean,
				default: true,
			},
			validateError: {
				type: String,
				default: '',
			}
		},
		data() {
			return {
				selected: null,
				options: [],
			}
		},
		watch: {
			value: function (newValue) {
				this.selected = this.setSelected(newValue, this.options, this.multiple)
			},
			faculty_code: function () {
				this.getOptions()
			},
			department_code: function () {
				this.getOptions()
			},
		},
		created() {
			this.getOptions()
		},
		methods: {
			translateNSelected,
			handleInput,
			setSelected,
			getOptions() {
				this.clearOptions();
				let filter = {
					faculty_code: this.faculty_code,
					department_code: this.department_code,
					major_type: this.major_type,
				};
				const config = {
					params: {
						filter: filter,
						sort: this.getLocaleField("name"),
						limit: -1,
					},
					paramsSerializer: (params) => qs.stringify(params, {encode: false})
				};
				ProgramServices.getAll(config)
				               .then((response) => {
					               this.options = [];
					               const data = response.data.data;
					               data.map((item) => {
						               this.options.push({
							               value: item.code,
							               text: item.code + ' - ' + this.getLocaleText(item, 'name'),
						               })
					               })
				               })
				               .then(() => {
					               this.selected = this.setSelected(this.value, this.options, this.multiple)
					               if (this.selected == null || this.selected.length == 0) {
						               this.$emit("input", null)
					               }
				               })
			},
			clearOptions() {
				this.selected = null;
				this.options = [];
			}
		}
	}
</script>
